header#header {
  background: rgba(0,0,0,0.6);
  left: 0;
  padding: 2rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.5s ease-in-out;

  .scrolling & {
    background: rgba(0,0,0,.6);
    padding: .5rem 0;
  }

  .logo {
    img {
      float: left;
      height: 50px;
      width: auto;
      transition: all ease-in-out .5s;

      .scrolling & {
        height: 42px;
      }
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .logo-mobile {
    display: none;

    @include media-breakpoint-down(xs) {
      display: block;
    }

    img {
      max-width: 50px;
      position: relative;
      z-index: 2;
    }
  }

  nav.nav-main {
    float: right;

    .page-home & {
      display: none;

      &.nav-main-home {
        display: block;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &.nav-main-home {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    a {
      color: $color-white;
      font-size: 20.18px;
      line-height: 50px;
      margin-left: 2rem;
      transition: all ease-in-out .5s;
      text-decoration: none;

      &:hover {
        color: $color-green;
      }

      &.active {
        color: $color-green;
        text-decoration: underline;
      }

      .page-contact & {
        &.contact {
          color: $color-green;
          text-decoration: underline;
        }
      }

      .page-about & {
        &.about {
          color: $color-green;
          text-decoration: underline;
        }
      }

      .scrolling & {
        font-size: 18px;
      }
    }
  }

  .page-contact & {
    background: rgba(0,0,0,.6);
  }
}

.mobile-menu-button {
  border: 2px solid $color-white;
  border-radius: 25px;
  color: $color-white;
  display: none;
  font-family: $type-dmsans-medium;
  font-size: 20px;
  line-height: 40px;
  padding: 0 2.5rem 0 1rem;
  position: absolute;
  text-align: left;
  top: 0;
  right: 2rem;
  transition: .5s all ease-in-out;
  z-index: 2;

  @include media-breakpoint-down(sm) {
    display: block;

  }

  .bars {
    border-bottom: 2px solid $color-white;
    opacity: 1;
    position: absolute;
    right: 1rem;
    top: 50%;
    transition: .5s all ease-in-out;
    width: 16px;

    &:before {
      border-bottom: 2px solid $color-white;
      content: '';
      opacity: 1;
      position: absolute;
      right: 0;
      top: -6px;
      transition: .5s all ease-in-out;
      width: 16px;
    }

    &:after {
      border-bottom: 2px solid $color-white;
      content: '';
      position: absolute;
      right: 0;
      bottom: -8px;
      transition: .5s all ease-in-out;
      width: 16px;
    }
  }

  &.active {

    .bars {
      transform: rotate(-45deg);

      &:before {
        opacity: 0;
      }

      &:after {
        bottom: -2px;
        transform: rotate(90deg);
      }
    }
  }
}

.menu-mobile {
  background: url(../img/menu-mobile-bg.jpg) no-repeat left top;
  background-size: cover;
  display: none;
  height: 100%;
  opacity: 0;
  padding: 10rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all .5s ease-in-out;
  z-index: 1;

  &.active {
    display: block;
    opacity: 1;

    .page-home & {
      display: none;
      opacity: 0;
    }

    &.menu-mobile-home {
      display: none;

      .page-home & {
        display: block;
        opacity: 1;
      }
    }
  }

  a {
    background: url(../img/arrow-right.png) no-repeat right center;
    background-size: 25px;
    border-top: 1px solid $color-white;
    color: $color-white;
    display: block;
    font-family: $type-dmsans-bold;
    font-size: 40px;
    line-height: 75px;
    margin: 0 auto;
    width: 80%;

    &:last-child {
      border-bottom: 1px solid $color-white;
    }
  }
}