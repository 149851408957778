body,
html {
  .menu-open {
    overflow: hidden;
  }
}

body {

  &.modal-open {
    overflow: hidden;
  }
}

section {
  //display: inline-block;
  //width: 100%;
}

.container {
  @include media-breakpoint-down(xs) {
    //margin: 0 auto;
    //max-width: 350px;
  }
}

.modal-background {
  background: rgba(0,0,0,.8);
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition : all .5s ease-in-out;
  width: 100%;
  z-index: 99;

  &.active {
    display: block;
    opacity: 1;
  }
}

.modal-container {
  background: $color-white;
  border: 1px solid $color-gray-2;
  box-shadow: 0 3px 12px 3px rgba(0,0,0,.2);
  display: none;
  height: 450px;
  left: 50%;
  opacity: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition : all .5s ease-in-out;
  width: 960px;
  z-index: 100;

  @include media-breakpoint-down(md) {
    height: auto;
    //transform: translate(0, 50%);
    width: 100%;
  }

  &.active {
    display: block;
    opacity: 1;
  }

  .modal-close {
    color: $color-green;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: -1.5rem;
    top: -2.5rem;
  }

  video {
    width: 100%;
  }
}