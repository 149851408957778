section.cta {
  background: $color-green;
  padding: 5rem 0 7rem;

  .container {
    position: relative;
  }

  p {
    color: $color-white;
    font-family: $type-dmsans-medium;
    font-size: 27.5px;
    line-height: 35px;
    text-align: center;
    width: 100%;

    @include media-breakpoint-down(xs) {
      font-size: 17.5px;
      line-height: 25px;
    }
  }

  button.animate {
    display: block;
    margin: 0 auto;
  }

  #footnote {
    position: absolute;
    bottom: -105px;
    left: 0;
    color: #ffffff;
    font-size: 14px;
    padding: 0 15px;
    line-height: 1.1;
  }
}

.page-about {
  #footnote {
    display: none;
  }
}

footer#footer {
  background: $color-gray;
  padding: 2rem 0;
  position: relative;

  .logo {

    img {
      float: left;
      height: 50px;

      @include media-breakpoint-down(sm) {
        display: block;
        float: none;
        margin: 0 auto 2rem;
      }
    }
  }

  .footer-legal {
    @include media-breakpoint-down(lg) {
      margin: 0 auto;
      width: 666px;
    }

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  .copyright {
    color: $color-white;
    float: left;
    font-size: 16px;
    line-height: 50px;
    padding: 0 0 0 2rem;

    @include media-breakpoint-down(sm) {
      display: block;
      margin: 0 auto;
      float: none;
      font-size: 11px;
      line-height: 23px;
      padding-left: 0;
      width: 100%;
      text-align: center;
    }
  }

  .nav-footer {
    float: right;

    @include media-breakpoint-down(lg) {
      float: none;
      margin: 0 auto;
      width: 430px;
    }

    @include media-breakpoint-down(md) {
      width: auto;
    }

    a {
      border-left: 1px solid $color-white;
      color: $color-white;
      float: left;
      font-size: 16px;
      line-height: 16px;
      margin: 17px 0 17px 1rem;
      padding: 0 0 0 1rem;

      @include media-breakpoint-down(sm) {
        font-size: 11px;
        line-height: 23px;
      }

      &:first-child {
        border-left: none;
      }
    }
  }
}