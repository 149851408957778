.page-404 {
  background: $color-gray;

  .hero {
    background: $color-black url(../img/bg-404.jpg) no-repeat bottom -1px right;
    height: 600px;

    h1 {
      color: $color-green;
      font-size: 125px;
      line-height: 80px;
      margin-top: 12rem;
    }

    p {
      color: $color-white;
      padding-top: 2rem;
    }
  }

  .arrow-pointer {
    display: none;
  }

  .cta {
    display: none;
  }
}