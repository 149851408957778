.page-home {
  section {
    &.hero {
      background: url(../img/home-hero-bg.jpg) no-repeat bottom left;
      background-attachment: fixed;
      background-size: cover;
      mask-image: url(../img/home-hero-bg-mask.png);
      mask-mode: alpha;
      mask-position: bottom center;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      //height: 775px;
      padding: 100px 0;
      position: relative;

      @include media-breakpoint-down(lg) {
        background: url(../img/home-hero-bg-tablet.png) no-repeat bottom center;
        background-size: auto 100%;
        height: 650px;
        padding-bottom: 50px;
        mask-image: none;
      }

      @include media-breakpoint-down(xs) {
        background: url(../img/home-hero-bg-mobile.png) no-repeat top right;
        background-size: 100% 100%;
        height: 650px;
        padding-bottom: 50px;
        mask-image: none;
      }

      .row:first-child {
        //padding-top: 200px;

        @include media-breakpoint-down(md) {
          //padding-top: 125px;
        }

        @include media-breakpoint-down(xs) {
          //padding-top: 150px;
        }
      }

      h1 {
        @include media-breakpoint-down(md) {
          margin-top: 25px;
        }
      }

      p {
        color: $color-white;
        font-size: 20px;

        @include media-breakpoint-down(lg) {
          font-size: 17px;
          line-height: 20px;
        }
      }

      .arrow-pointer {
        bottom: 0px;
        position: absolute;
        left: calc(50% - 33px);

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
    &.section-1 {
      padding-top: 10rem;

      @include media-breakpoint-down(sm) {
        padding-top: 4rem;
      }

      h2 {
        a {
          color: #99ca3c;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .cup-1 {
        position: absolute;
        right: 0;
        top: -375px;
        z-index: 1;

        @include media-breakpoint-down(lg) {
          top: -300px;
        }

        @include media-breakpoint-down(md) {
          top: -350px;
        }

        @include media-breakpoint-down(sm) {
          top: -500px;
          //right: 20%;
          //transform: translateX(50%);
          width: 180px;
        }

        @media (max-width: 370px) {
          top: -92%;
        }
      }

      .steps {
        height: 575px;
        position: relative;

        @include media-breakpoint-down(lg) {
          height: 685px;
        }

        @include media-breakpoint-down(md) {
          height: 600px;
        }

        @include media-breakpoint-down(xs) {
          height: 525px;
        }

        .curved-line {
          border: 2px dotted;
          border-color: #959595 transparent transparent transparent;
          border-radius: 50%/50px 50px 0 0;
          height: 100px;
          position: absolute;
          width: 200px;
          z-index: -1;

          @include media-breakpoint-down(xs) {
            height: 75px;
            width: 150px;
          }

          &.curved-line-1 {
            right: 250px;
            top: 175px;
            transform: rotate(60deg);

            @include media-breakpoint-down(lg) {
              right: 150px;
              top: 225px;
            }

            @include media-breakpoint-down(md) {
              right: 100px;
              top: 200px;
            }

            @include media-breakpoint-down(sm) {
              right: 220px;
              top: 200px;
            }

            @include media-breakpoint-down(xs) {
              //right: 115px;
              top: 150px;
              left: 65px;
            }
          }

          &.curved-line-2 {
            right: 250px;
            top: 284px;
            transform: rotate(120deg);

            @include media-breakpoint-down(lg) {
              right: 150px;
              top: 330px;
            }

            @include media-breakpoint-down(md) {
              right: 100px;
              top: 305px;
            }

            @include media-breakpoint-down(sm) {
              right: 220px;
              top: 305px;
            }

            @include media-breakpoint-down(xs) {
              //right: 115px;
              top: 220px;
              left: 65px;
            }
          }
        }

        .step {
          position: absolute;

          .dot {
            background: $color-green;
            border-radius: 50%;
            display: inline-block;
            height: 16px;
            position: relative;
            width: 16px;
          }

          .step-copy {
            color: $color-gray;
            font-size: 22px;
            line-height: 30px;
            position: absolute;

            @include media-breakpoint-down(xs) {
              font-size: 13px;
              line-height: 15px;
            }

            &:before,
            &:after {
              font-family: $type-avenir-regular;
              font-size: 90px;
              position: absolute;

              @include media-breakpoint-down(xs) {
                font-size: 55px;
              }
            }
          }


          // Step 1------------||
          &.step-1 {
            top: 0;

            .dot {
              bottom: 4px;
              right: -260px;

              @include media-breakpoint-down(lg) {
                bottom: -45px;
                right: -250px;
              }

              @include media-breakpoint-down(md) {
                bottom: -35px;
                right: -165px;
              }

              @include media-breakpoint-down(xs) {
                bottom: -85px;
              }

              @include media-breakpoint-down(xs) {
                bottom: 60px;
                position: absolute;
                right: 60px;
                top: auto;
              }
            }

            img {
              width: 218px;

              @include media-breakpoint-down(md) {
                top: 2rem;
                position: relative;
                width: 200px;
                right: 45px;
              }
              @include media-breakpoint-down(xs) {
                width: 150px;
                right: 15px;
              }
            }

            .step-copy {
              bottom: 144px;
              right: -380px;

                &:before {
                bottom: 1.8rem;
                content: '1';
                display: inline-block;
                left: -3.5rem;

                @include media-breakpoint-down(xs) {
                  bottom: .5rem;
                  left: -2rem;
                }
              }

              h3 {
                color: #666666;
                text-align: left;
                font-size: 1.8rem;
                line-height: 1.2;
                font-weight: 700;
                position: relative;

                @include media-breakpoint-down(lg) {
                  font-size: 1.6rem;
                }
                @include media-breakpoint-down(md) {
                  font-size: 1.2rem;
                }

                @include media-breakpoint-down(sm) {
                  font-size: 1.2rem;
                  //left: -190px;
                  //top: -30px;
                }
                @include media-breakpoint-down(xs) {
                  font-size: 1rem;
                  left: -185px;
                  top: 20px;
                }

                  a {
                    color: #666666;

                    &:hover {
                      text-decoration: none;
                    }
                  }
              }

              h4 {
                font-weight: 700;
                padding: 10px 0 8px;
                position: relative;

                @include media-breakpoint-down(lg) {
                  font-size: 1.2rem;
                }

                @include media-breakpoint-down(md) {
                  font-size: 1rem;
                }

                @include media-breakpoint-down(xs) {
                  font-size: .8rem;
                  left: -20px;
                  top: -5px;
                }
              }

              p {
                margin: 0;
              }

              @include media-breakpoint-down(lg) {
                bottom: 110px;
                right: -315px;
                font-size: 1.2rem;
                line-height: 1.6rem;
              }

              @include media-breakpoint-down(md) {
                bottom: 125px;
                right: -175px;
                font-size: 1rem;
                line-height: 1.4rem;
              }

              @include media-breakpoint-down(sm) {
                bottom: 110px;
              }

              @include media-breakpoint-down(xs) {
                bottom: 70px;
                right: -140px;
                font-size: .8rem;
                line-height: 1rem;
              }
            }

            @include media-breakpoint-down(xs) {
              width: 200px;
            }
          }

          // Step 2------------||
          &.step-2 {
            right: 105px;
            top: 250px;

            .dot {
              bottom: 120px;
              right: -7px;

              @include media-breakpoint-down(lg) {
                bottom: 205px;
                right: 25px;
              }

              @include media-breakpoint-down(md) {
                bottom: 250px;
                right: -50px;
              }

              @include media-breakpoint-down(sm) {
                bottom: 215px;
                right: 120px;
              }

              @include media-breakpoint-down(xs) {
                bottom: auto;
                position: absolute;
                right: 135px;
                top: -90px;
              }
            }

            img {
              position: relative;
              left: -60px;
              top: 0rem;
              width: 175px;

              @include media-breakpoint-down(lg) {
                left: -95px;
                top: -58px;
                width: 175px;
              }

              @include media-breakpoint-down(md) {
                left: auto;
                top: -85px;
                width: 200px;
              }

              @include media-breakpoint-down(sm) {
                left: -175px;
                top: -100px;
                width: 150px;
              }

              @include media-breakpoint-down(xs) {
                left: 15px;
                top: -100px;
                width: 150px;
              }

            }

            .step-copy {
              bottom: 233px;
              right: -150px;
              width: 250px;

              &:before {
                bottom: 1.8rem;
                content: '2';
                display: inline-block;
                left: -4rem;

                @include media-breakpoint-down(xs) {
                  bottom: 2.5rem;
                  left: -2.3rem;
                }
              }

              @include media-breakpoint-down(lg) {
                bottom: 325px;
                right: 5px;
                line-height: 1.6rem;
                font-size: 1.2rem;
                width: 125px;
              }

              @include media-breakpoint-down(md) {
                bottom: 385px;
                right: -15px;
                font-size: 1rem;
                line-height: 1.4rem;
                width: 100px;
              }

              @include media-breakpoint-down(sm) {
                bottom: 325px;
                right: 0px;
                width: 200px;
              }

              @include media-breakpoint-down(xs) {
                bottom: 310px;
                right: -10px;
                font-size: .8rem;
                line-height: 1rem;
                width: 100px;
              }
            }

            @include media-breakpoint-down(lg) {
              right: -25px;
              top: 360px;
            }

            @include media-breakpoint-down(xs) {
              //right: 0;
              top: 300px;
              width: 200px;
              left: 130px;
            }
          }

          // Step 3------------||
          &.step-3 {
            bottom: 0;

            .dot {
              bottom: 25px;
              right: -260px;

              @include media-breakpoint-down(lg) {
                bottom: 92px;
                right: -250px;
              }

              @include media-breakpoint-down(md) {
                bottom: 65px;
                right: -160px;
              }

              @include media-breakpoint-down(xs) {
                bottom: auto;
                position: absolute;
                right: 110px;
                top: -60px;
              }
            }

            img {
              width: 350px;

              @include media-breakpoint-down(md) {
                width: 250px;
              }
              @include media-breakpoint-down(xs) {
                width: 225px;
              }
            }

            .step-copy {
              bottom: 186px;
              left: 30px;

              &:after {
                bottom: 1.65rem;
                content: '3';
                display: inline-block;
                right: -4rem;

                @include media-breakpoint-down(xs) {
                  bottom: 1rem;
                  right: -2.5rem;
                }
              }

              @include media-breakpoint-down(lg) {
                bottom: 186px;
                left: 30px;
                font-size: 1.2rem;
                line-height: 1.6rem;
              }

              @include media-breakpoint-down(md) {
                bottom: 170px;
                left: -5px;
                font-size: 1rem;
                line-height: 1.4rem;
              }

              @include media-breakpoint-down(xs) {
                bottom: 150px;
                left: 15px;
                font-size: 0.8rem;
                line-height: 1rem;
              }
            }

            @include media-breakpoint-down(xs) {
              width: 250px;
            }
          }

          img {
            position: relative;
            z-index: -2;
          }
        }
      }
    }

    &.section-2 {
      background: url(../img/home-bg-section-2.jpg) no-repeat top center, linear-gradient($color-body-background 894px, #fff 282px);
      height: 1176px;
      margin-bottom: -282px;
      overflow: visible;

      @include media-breakpoint-down(sm) {
        background: url(../img/home-bg-section-2-mobile.png) no-repeat top center;
        background-size: auto 100%;
        margin-bottom: 0;
      }

      img {
        margin-top: 12rem;

        @include media-breakpoint-down(md) {
          display: block;
          margin: 18rem auto 0 3rem;
          width: 300px;
        }
      }

      h2 {
        margin-top: 16rem;

        @include media-breakpoint-down(sm) {
          margin-top: 4rem;
        }
      }
    }

    &.section-3 {
      padding-top: 200px;

      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }

      .floating-image {
        position: relative;
        width: 100%;
        text-align: center;

        .cup-shadow {
          animation: shrink 1.25s;
          animation-timing-function: linear;
          animation-direction: alternate;
          animation-iteration-count: infinite;
          background: url('../img/home-img-section-3-shadow.png');
          display: block;
          height: 71px;
          top: 18rem;
          left: 7rem;
          position: relative;
          width: 325px;

          @include media-breakpoint-down(md) {
            left: 2rem;
            top: 2rem;
          }
        }
      }

      img {
        //margin: -500px 0 0 6rem;
        left: 7rem;
        position: absolute;
        animation: bounce 1.25s;
        animation-direction: alternate;
        //animation-timing-function: cubic-bezier(.5,0.05,1,.5);
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        top: -2rem;

        @include media-breakpoint-down(md) {
          width: 300px;
        }

        @include media-breakpoint-down(sm) {
          display: block;
          margin: -150px auto 0 -25px;
          width: 200px;
        }
      }

      h2 {
        @include media-breakpoint-down(sm) {
          margin-top: 4rem;
        }
      }
    }

    &.section-4 {
      padding-bottom: 5rem;
      text-align: center;

      @include media-breakpoint-down(xs) {
        padding-bottom: 4rem;
      }

      &:before {
        box-shadow: 0 25px 19px -22px rgba(0,0,0,.3);
        content: '';
        display: block;
        height: 100px;
        margin: 3rem auto 0;
        width: 35%;

        @include media-breakpoint-down(md) {
          margin: 0rem auto 0;
        }

        @include media-breakpoint-down(sm) {
          height: 50px;
          margin: 1rem auto 0;
          width: 75%;
        }
      }

      p {
        text-align: center;
        padding-top: 6rem;

        @include media-breakpoint-down(md) {
          padding-top: 6rem;
        }

        @include media-breakpoint-down(sm) {
          padding-top: 4rem;
        }
      }

      img {
        display: block;
        margin: 3rem auto 0;
        width: 100%;
        max-width: 300px;
        position: relative;
        left: 40px;

        @include media-breakpoint-down(xs) {
          margin-top: 4rem;
          max-width: 200px;
          left: 26px;
        }
      }
    }

    .video-play-container {
      position: relative;

      .icon-play {
        background: rgba(0,0,0,.5);
        border: $color-green 2px solid;
        border-radius: 25px;
        color: $color-white;
        cursor: pointer;
        font-family: $type-dmsans;
        font-size: 23px;
        top: 68%;
        left: calc(50% - 100px);
        margin: 0;
        padding: .4rem 0;
        position: absolute;
        transition: all ease-in-out .5s;
        width: 200px;

        &:hover {
          background: rgba(0,0,0,.8);
          transform: scale(1.2);
        }

        .icon {
          display: inline;

          &:after {
            color: $color-white;
            content: '\25B7';
            font-size: 20px;
          }
        }

        @include media-breakpoint-down(lg) {
          //left: 46%;
          //width: 60px;
        }

        @include media-breakpoint-down(md) {
          //left: 45%;
          font-size: 16px;
          width: 150px;
          left: calc(50% - 75px);
        }

        @include media-breakpoint-down(xs) {
          //width: 32px;
        }
      }
    }
  }

  .button.email-link {
    bottom: 260px;
    position: fixed;
    right: 1rem;
    width: 15rem;
    z-index: 1;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .icon-email {
      left: auto;
      right: 1.5rem;
      position: absolute;
      top: .75rem;
      transition: all .5s ease-in-out;

      img {
        display: inline;
        width: 24px;
      }
    }

    .button-text {
      margin: 0;
      opacity: 0;
      text-align: center;
      width: 1px;
    }

    .circle {
      right: .75rem;
      left: auto;
      position: absolute;
      z-index: -1;

      .icon {
        opacity: 0;

        &.arrow {
          &:before {
            border-color: $color-white;
          }
        }
      }
    }

    &:hover {

      .icon-email {
        right: 11.5rem;

      }

      .button-text {
        opacity: 1;
        width: 100%;
      }

      .circle {
        width: 90%;

        .icon {
          opacity: 1;
          &.arrow {
            left: auto;
            right: .5rem;

            &:before {
            }
          }
        }

      }
    }
  }

  .button.video-link {
    bottom: 200px;
    position: fixed;
    right: 1rem;
    width: 15rem;
    z-index: 1;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .icon-video {
      left: auto;
      right: 1.5rem;
      position: absolute;
      top: .75rem;
      transition: all .5s ease-in-out;

      img {
        display: inline;
        width: 24px;
      }
    }

    .button-text {
      margin: 0;
      opacity: 0;
      text-align: center;
      width: 1px;
    }

    .circle {
      right: .75rem;
      left: auto;
      position: absolute;
      z-index: -1;

      .icon {
        opacity: 0;

        &.arrow {
          &:before {
            border-color: $color-white;
          }
        }
      }
    }

    &:hover {

      .icon-video {
        right: 11.5rem;

      }

      .button-text {
        opacity: 1;
        width: 100%;
      }

      .circle {
        width: 90%;

        .icon {
          opacity: 1;
          &.arrow {
            left: auto;
            right: .5rem;

            &:before {
            }
          }
        }

      }
    }
  }

  img {
    max-width: 100%;
  }

}

// Custom animations

[data-aos="cup-movement-1"] {
  opacity: 1;
  transform: translate(0 0);
  transition: all .5s ease-in-out;

  &.aos-animate {
    opacity: 0;
    transform: translate(-382px, 400px);

    @include media-breakpoint-down(lg) {
      transform: none;
      opacity: 1;
    }
  }
}

@keyframes bounce {
  from { transform: translate3d(0, 0, 0);     }
  to   { transform: translate3d(0, 50px, 0); }
}

@keyframes shrink {
  from { transform: scale(.6); }
  to { transform: scale(1); }
}