// Basics
$color-black: #000;
$color-white: #fff;

// Grays
$color-gray: #666666;
$color-gray-2: #e9ecf0;

// Theme colors
$color-green: #99ca3c;
.color-green { color: $color-green; }

$color-body-text: $color-gray;
$color-body-background: $color-gray-2;