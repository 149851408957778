.page-about {

  section {

    &.hero {
      background: url(../img/about-hero-bg.png) no-repeat 0 100%;
      background-attachment: fixed;
      background-size: cover;
      mask-image: url(../img/home-hero-bg-mask.png);
      mask-mode: alpha;
      mask-position: bottom center;
      mask-repeat: no-repeat;
      mask-size: 100%;
      //height: 775px;
      padding: 200px 0;
      position: relative;

      @include media-breakpoint-down(lg) {
        //background: url(../img/home-hero-bg-tablet.png) no-repeat bottom center;
        background-size: auto 100%;
        height: 650px;
        padding-bottom: 50px;
        mask-image: none;
      }

      @include media-breakpoint-down(xs) {
        //background: url(../img/home-hero-bg-mobile.png) no-repeat bottom right;
        background-size: auto 100%;
        height: 650px;
        padding-bottom: 50px;
        mask-image: none;
      }

      h1 {
        font-size: 75px;
        line-height: 80px;
        //margin-top: 250px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 47px;
          line-height: 50px;
          margin-top: 5rem;
        }
      }

      p {
        color: $color-white;
        font-size: 20px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 17.6px;
          line-height: 25px;
        }
      }
    }

    &.section-1,
    &.section-3 {
      padding: 5rem 0;

      @include media-breakpoint-down(sm) {
        padding: 2rem 0;
      }
    }

    &.section-2 {
      background: url(../img/about-bg-section-2.jpg) no-repeat center center;
      background-attachment: fixed;
      background-size: cover;

      h3 {
        margin: 12rem 0;
      }
    }
  }

  .arrow-pointer {
    bottom: 0px;
    position: absolute;
    left: calc(50% - 28px);

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

}