// Watch Video Button

$bg: #f3f8fa;
$white: #fff;
$black: #282936;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
  transition: $property $duration $ease;
}

button.animate,
.button.animate {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  margin: 0 auto;
  padding: 0;
  width: 13rem;
  height: auto;

  .circle {
    @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
    border: 2px solid $color-green;
    position: relative;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: transparent;
    border-radius: 1.625rem;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .icon {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background: $color-white;

      &.arrow {
        @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
        left: 0.425rem;
        width: 1.125rem;
        height: 0.125rem;
        background: none;

        &::before {
          position: absolute;
          content: '';
          top: -0.25rem;
          right: 0.0625rem;
          width: 0.625rem;
          height: 0.625rem;
          border-top: 0.125rem solid #fff;
          border-right: 0.125rem solid #fff;
          transform: rotate(45deg);
        }
      }
    }
  }

  &.white {
    .circle {
      border-color: $color-green;
      background: $color-white;

      .arrow {
        &::before {
          border-color: $color-green;
        }
      }
    }
  }

  &.gray {
    .circle {
      border-color: $color-white;
      background: $color-gray;

      .arrow {
        &::before {
          border-color: $color-green;
        }
      }
    }
  }

  &.right {
    .circle {
      position: absolute;
      right: 0;

      .icon {
        &.arrow {
          right: 1rem;
          left: auto;
        }
      }
    }
  }

  .button-text {
    @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
    color: $color-green;
    font-size: 20px;
    line-height: 26px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 0 0 1.85rem;
    padding: 0.75rem 0;
    text-align: center;
  }

  &.white {
    .button-text {
      color: $color-white;
    }
  }

  &.gray {
    .button-text {
      //opacity: 0;
    }
  }

  &.right {
    .button-text {
      margin: 0 1.85rem 0 0;
    }
  }

  &:hover {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
          background: $color-white;
          transform: translate(1rem, 0);
        }
      }
    }
    .button-text {
    }
  }

  &.right {
    &:hover {
      .icon {
        &.arrow {
          right: .425rem;
          transform: translate(-1rem, 0);
        }
      }
    }
  }

  &.white {
    &:hover {

      .circle {
        .icon {
          &.arrow {
            background: $color-green;
          }
        }
      }

      .button-text {
        color: $color-black;
      }
    }

    @include media-breakpoint-down(sm) {

      .circle {
        .icon {
          &.arrow {
            background: $color-green;
          }
        }
      }

      .button-text {
        color: $color-black;
      }
    }
  }

  &.gray {
    &:hover {
      .button-text {
        color: $color-white;
        display: inline;
        opacity: 1;
      }
    }
  }
}

// Inputs

input {
  background: $color-white;
  color: $color-gray;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  line-height: 27px;
  padding: 1rem 1rem;
  width: 100%;

  &:focus,
  &.active {
    outline: none;
  }

  &[type="submit"] {
    background: $color-black;
    color: $color-white;
    display: block;
    margin: 0 auto;
    width: 14rem;
  }
}

textarea {
  background: $color-white;
  color: $color-gray;
  border: none;
  border-radius: 25px;
  box-sizing: content-box;
  font-size: 20px;
  height: 27px;
  line-height: 27px;
  padding: 1rem 1rem;
  width: calc(100% - 2rem);
  transition: all .5s ease-in-out;

  &:focus,
  &.active {
    outline: none;
    height: 175px;
  }
}

// down arrow
@mixin rotate($angle) {
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -webkit-transform: rotate($angle);
  transform: rotate($angle);
}

@mixin arrow($arrowSize: 20px, $arrowBorderSize: 3px, $arrowBarWidth: 2px, $arrowBarHeight: 25px) {
  display: inline-block;
  height: $arrowSize;
  position: relative;
  width: $arrowSize;

  &::after {
    border-bottom-style: solid;
    border-bottom-width: $arrowBorderSize;
    border-right-style: solid;
    border-right-width: $arrowBorderSize;
    content: '';
    display: inline-block;
    height: $arrowSize;
    left: 0;
    position: absolute;
    top: 0;
    width: $arrowSize;
  }

  // Triangle shape state
  &.is-triangle::after {
    border-style: solid;
    border-width: 0 0 $arrowSize $arrowSize;
    height: 0;
    width: 0;
  }

  // Check shape state
  &.is-check::after {
    width: $arrowSize / 2;
  }

  // Arrow Bar
  &.arrow-bar::before {
    bottom: 1px;
    content: '';
    height: $arrowBarHeight;
    position: absolute;
    right: 0;
    transform-origin: bottom;
    width: $arrowBarWidth;
    @include rotate(-45deg);
  }

  // States
  &.is-top {
    @include rotate(225deg);
  }

  &.is-right {
    @include rotate(315deg);
  }

  &.is-bottom {
    @include rotate(45deg);
  }

  &.is-left {
    @include rotate(135deg);
  }
}

@mixin arrowColor($color) {
  &,
  .arrow {
    &::after {
      border-color: $color;
    }

    &.is-triangle::after {
      border-color: transparent;
      border-bottom-color: $color;
    }

    &::before {
      background-color: $color;
    }
  }
}

.arrow-pointer {
  margin: 25px;
  @include arrow(12px, 2px, 2px, 20px);
  @include arrowColor($color-green);
}

.button.email-link {
  bottom: 260px;
  position: fixed;
  right: 1rem;
  width: 15rem;
  z-index: 1;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .icon-email {
    left: auto;
    position: absolute;
    top: .75rem;
    right: 1.5rem;
    transition: all .5s ease-in-out;

    img {
      display: inline;
      width: 24px;
    }
  }

  .button-text {
    margin: 0;
    opacity: 0;
    text-align: center;
  }

  .circle {
    right: .75rem;
    position: absolute;
    left: auto;
    z-index: -1;

    .icon {
      opacity: 0;

      &.arrow {
        &:before {

        }
      }
    }
  }

  &:hover {

    .button-text {
      opacity: 1;
    }

    .icon-email {
      right: 11.5rem;
    }

    .circle {
      width: 90%;

      .icon {
        opacity: 1;
        &.arrow {
          left: auto;
          right: 2rem;

          &:before {
            border-color: $color-white;
          }
        }
      }

    }
  }
}

.button.video-link {
  bottom: 200px;
  position: fixed;
  right: 1rem;
  width: 15rem;
  z-index: 1;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .icon-video {
    left: auto;
    position: absolute;
    top: .75rem;
    right: 1.5rem;
    transition: all .5s ease-in-out;

    img {
      display: inline;
      width: 24px;
    }
  }

  .button-text {
    margin: 0;
    opacity: 0;
    text-align: center;
  }

  .circle {
    right: .75rem;
    position: absolute;
    left: auto;
    z-index: -1;

    .icon {
      opacity: 0;

      &.arrow {
        &:before {

        }
      }
    }
  }

  &:hover {

    .button-text {
      opacity: 1;
    }

    .icon-video {
      right: 11.5rem;
    }

    .circle {
      width: 90%;

      .icon {
        opacity: 1;
        &.arrow {
          left: auto;
          right: 2rem;

          &:before {
            border-color: $color-white;
          }
        }
      }

    }
  }
}