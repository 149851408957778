@font-face {
  font-family: 'DM Sans Regular';
  src: url('../fonts/dmsans-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dmsans-regular-webfont.otf') format('otf'),
  url('../fonts/dmsans-regular-webfont.woff') format('woff'),
  url('../fonts/dmsans-regular-webfont.ttf') format('truetype'),
  url('../fonts/dmsans-regular-webfont.svg#dmsans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans Italic';
  src: url('../fonts/dmsans-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dmsans-italic-webfont.otf') format('otf'),
  url('../fonts/dmsans-italic-webfont.woff') format('woff'),
  url('../fonts/dmsans-italic-webfont.ttf') format('truetype'),
  url('../fonts/dmsans-italic-webfont.svg#dmsans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans Medium';
  src: url('../fonts/dmsans-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dmsans-medium-webfont.otf') format('otf'),
  url('../fonts/dmsans-medium-webfont.woff') format('woff'),
  url('../fonts/dmsans-medium-webfont.ttf') format('truetype'),
  url('../fonts/dmsans-medium-webfont.svg#dmsans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans Medium Italic';
  src: url('../fonts/dmsans-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dmsans-mediumitalic-webfont.otf') format('otf'),
  url('../fonts/dmsans-mediumitalic-webfont.woff') format('woff'),
  url('../fonts/dmsans-mediumitalic-webfont.ttf') format('truetype'),
  url('../fonts/dmsans-mediumitalic-webfont.svg#dmsans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans Bold';
  src: url('../fonts/dmsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dmsans-bold-webfont.otf') format('otf'),
  url('../fonts/dmsans-bold-webfont.woff') format('woff'),
  url('../fonts/dmsans-bold-webfont.ttf') format('truetype'),
  url('../fonts/dmsans-bold-webfont.svg#dmsans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans Bold Italic';
  src: url('../fonts/dmsans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/dmsans-bolditalic-webfont.otf') format('otf'),
  url('../fonts/dmsans-bolditalic-webfont.woff') format('woff'),
  url('../fonts/dmsans-bolditalic-webfont.ttf') format('truetype'),
  url('../fonts/dmsans-bolditalic-webfont.svg#dmsans-regular-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Regular';
  src: url('../fonts/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNext-Regular.otf') format('otf'),
  url('../fonts/AvenirNext-Regular.woff') format('woff'),
  url('../fonts/AvenirNext-Regular.ttf') format('truetype'),
  url('../fonts/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}