.page-contact {
  background: $color-gray-2;

  section {

    &.hero {
      background: url(../img/contact-hero-bg.png) no-repeat bottom right;
      background-attachment: fixed;
      background-size: cover;
      mask-image: url(../img/home-hero-bg-mask.png);
      mask-mode: alpha;
      mask-position: bottom center;
      mask-repeat: no-repeat;
      mask-size: 100%;
      padding: 200px 0;
      position: relative;

      @include media-breakpoint-down(lg) {
        //background: url(../img/home-hero-bg-tablet.png) no-repeat bottom center;
        background-size: auto 100%;
        height: 650px;
        padding-bottom: 50px;
        mask-image: none;
      }

      @include media-breakpoint-down(xs) {
        //background: url(../img/home-hero-bg-mobile.png) no-repeat bottom right;
        background-size: auto 100%;
        height: 650px;
        padding-bottom: 50px;
        mask-image: none;
      }

      @include media-breakpoint-down(sm) {
        background: url(../img/contact-hero-bg.png) no-repeat top left;
      }

      h1 {
        font-size: 75px;
        line-height: 80px;
        //margin-top: 250px;
        text-align: center;

        @include media-breakpoint-down(sm) {
          font-size: 47px;
          line-height: 50px;
        }
      }

      p {
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        padding: 20px 0;
      }
    }

    &.form {
      //height: 550px;

      @include media-breakpoint-down(sm) {
        //height: 500px;
      }

      form {
        transform: translateY(-252px);

        @include media-breakpoint-down(lg) {
          transform: translateY(-240px);
        }

        .form-line {
          display: inline-block;
          margin-top: 2rem;
          position: relative;
          width: 100%;

          label {
            color: $color-gray;
            font-size: 20px;
            line-height: 27px;
            left: 1rem;
            position: absolute;
            top: 25%;
            transition: all ease-in-out .5s;
          }

          .input {
            //width: 100%;

            &.active ~ label {
              font-size: 16px;
              color: $color-white;
              top: -1.5rem;
            }

            &.active ~ label.gray {
              color: $color-gray;
            }
          }

          textarea {
            //width: 100%;

            &.active ~ label {
              font-size: 16px;
              color: $color-white;
              top: -1.5rem;
            }

            &.active ~ label.gray {
              color: $color-gray;
            }
          }

          .counter {
            color: $color-gray;
            font-size: .75rem;
            text-align: right;
          }


        }

        p.note {
          font-size: 15px;
          line-height: 30px;
          padding: .5rem 0 0 1rem;
        }
        div.wpcf7-validation-errors {
          border: 2px solid #ff0000;
          background-color: white;
          margin-top: 60px;
        }
      }
    }

    &.cta {
      display: none;
    }
  }

}