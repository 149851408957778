// Font Families
$type-dmsans                : 'DM Sans Regular', Arial, sans-serif;
$type-dmsans-italic         : 'DM Sans Italic', Arial, sans-serif;
$type-dmsans-medium         : 'DM Sans Medium', Arial, sans-serif;
$type-dmsans-medium-italic  : 'DM Medium Italic', Arial, sans-serif;
$type-dmsans-bold           : 'DM Sans Bold', Arial, sans-serif;
$type-dmsans-bold-italic    : 'DM Sans Bold Italic', Arial, sans-serif;
$type-avenir-regular        : 'Avenir Next Regular', Arial, sans-serif;

body {
  font-family: $type-dmsans;
  font-size: 16px;
  font-weight: normal;
}

strong,
b {
  font-family: $type-dmsans-bold;
}

h1, h2, h3, h4, h5, h6 {
  font-style: normal;
}

h1 {
  color: $color-white;
  font-family: $type-dmsans-bold;
  font-size: 65px;
  line-height: 1.1;

  @include media-breakpoint-down(lg) {
    font-size: 47px;
    line-height: 50px;
  }
}

h2 {
  color: $color-green;
  font-family: $type-dmsans-medium;
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    font-size: 27.5px;
    line-height: 30px;
  }
}

h3 {
  color: $color-white;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
}

p {
  color: $color-body-text;
  font-size: 22px;
  line-height: 30px;

  @include media-breakpoint-down(lg) {
    font-size: 17.5px;
    line-height: 25px;
  }
}

ul {

  li {
    color: $color-body-text;
    font-size: 22px;
    line-height: 30px;

    @include media-breakpoint-down(xs) {
      font-size: 17.5px;
      line-height: 25px;
    }
  }
}

tm {
  &:after {
    content: '\00AE';
    font-size: .75em;
    line-height: 1em;
    vertical-align: 35%;

    h1 & {
      font-size: .5em;
      vertical-align: 60%;
    }
  }

  &.tm {
    &:after {
      content: '\2122';
    }
  }
}

nw {
  white-space: nowrap;
}

br {
  &.desktop {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}